import React, { FC } from 'react';

import ProductCard from 'components/cards/ProductCard';

import { IPropsProductsList } from './models';

import './ProductsList.scss';

const ProductsList: FC<IPropsProductsList> = ({ products, isSmallDevice }) => (
  <div data-testid="ProductsList" className="products-list">
    {products?.map((item) => (
      <ProductCard key={item.url} data={item} variant={isSmallDevice ? 'vertical' : 'default'} />
    ))}
  </div>
);

export default ProductsList;
