import React, { FC, ReactElement } from 'react';
import useScreenRecognition from 'hooks/useScreenRecognition';

import ConnectedCustomRefinementList from 'components/algolia/CustomRefinementList';
import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography';

import helpers from './helpers';
import { IPropsFilterPanel } from './models';

import './FilterPanel.scss';

const FilterPanel: FC<IPropsFilterPanel> = ({
  filterBlocks,
  filtersSectionData,
  handleRemoveSelectionData,
  chosenFilterIds,
  isSmallDevice,
  itemsTotal,
  handleCloseSidebar,
  category,
  clearAllBtn,
  handleResetSelectionData,
}): ReactElement | null => {
  const { isNotMobile } = useScreenRecognition();

  return (
    <div data-testid="FilterPanel" className="filter-panel">
      <div className="filter-panel__wrapper">
        {isNotMobile ? (
          <Typography data={filtersSectionData.title} customClass="filter-panel__title" />
        ) : null}

        {isSmallDevice ? (
          <Button
            data={filtersSectionData.closeBtn}
            clickHandler={handleCloseSidebar}
            className="filter-panel__close"
          />
        ) : null}

        <div className="filter-panel__content">
          {filterBlocks?.length
            ? filterBlocks.map(({ attributeForFaceting, filterItems, header }) => (
                <ConnectedCustomRefinementList
                  key={attributeForFaceting}
                  attribute={attributeForFaceting}
                  /* @ts-ignore */
                  filterItems={filterItems}
                  blockId={attributeForFaceting}
                  title={header}
                  chosenFilterIds={chosenFilterIds}
                  handleRemoveSelectionData={handleRemoveSelectionData}
                  category={category}
                />
              ))
            : null}
        </div>

        {isSmallDevice ? (
          <div className="filter-panel__buttons">
            <Button
              data={[
                {
                  ...filtersSectionData.showResultsBtn[0],
                  ...(chosenFilterIds?.length
                    ? {
                        label: helpers.getResultsBtnLabel(
                          itemsTotal,
                          filtersSectionData.showResultsSingleTitle,
                          filtersSectionData.showResultsMultipleTitle
                        ),
                      }
                    : {}),
                },
              ]}
              disabled={Boolean(!chosenFilterIds?.length)}
              clickHandler={handleCloseSidebar}
            />

            {chosenFilterIds?.length ? (
              <Button
                data={clearAllBtn}
                clickHandler={handleResetSelectionData}
                className="filter-panel__clear-all"
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FilterPanel;
